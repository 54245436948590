<template>
  <div
    fluid
    class="pa-0"
  >
    <v-card
      class="test-plans-add-runs pt-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="test-plans-add-runs-header">
        <div class="d-flex align-center">
          <button
            plain
            class="btn-nav-back font-inter"
            @click="handleBackClick($event)"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <span class="d-flex-inline justify-center align-center ma-0 blue--text">
              {{ $t('plans.create.backToCreatePlans') }}
            </span>
          </button>
          <button
            class="btn-close ml-auto"
            @click="handleBackClick($event)"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </div>

        <h1 class="mt-4 font-inter text-start">
          {{ $t('testRuns') }}
        </h1>
        <div class="mt-4 d-flex">
          <v-chip
            class="font-inter font-weight-bold px-4"
            width="200px"
            label
            :class="{ 'blue--text': tableFilter === 'all' }"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('all')"
          >
            {{ $t('all') }} <span class="ml-2">{{ runs.length }}</span>
          </v-chip>
          <v-chip
            class="font-inter font-weight-bold px-4 ml-2"
            label
            width="200px"
            :class="{ 'blue--text': tableFilter === 'selected' }"
            :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('selected')"
          >
            {{ $t('selected') }} <span class="ml-2">{{ selectedTestRunsLength }}</span>
          </v-chip>
        </div>
      </div>
      <div
        v-if="runs.length <= 0"
        class="d-flex flex-column align-center mt-6"
      >
        <div class="d-flex flex-row justify-center">
          <v-img
            :src="require('@/assets/png/auth-banner.png')"
            alt="Table empty state"
            width="100%"
            max-width="438"
          />
        </div>
        <h2>{{ $t('plans.beginTestNewTitle') }}</h2>
        <p
          class="text-center"
          style="max-width:438px;"
        >
          {{ $t('plans.beginTestNewDescripiton') }}
        </p>
        <v-btn
          class="text-capitalize primary px-6"
          depressed
          @click="openCreateTestRun"
        >
          {{ $t('testruns.create_testrun.title') }}
          <v-icon size="16">
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <div
        v-else
        class="test-plans-add-runs-wrapper mt-4"
      >
        <div class="search-container d-flex">
          <search-field
            v-model="searchFilter"
            class="search-input"
            :placeholder="$t('searchByName')"
          />
          <RunFilter
            :configurations="[]" 
            @filters="applyFilters"
          />
          <div class="ml-auto">
            <SettingsMenu 
              table-type="addTestRun" 
            />
          </div>
        </div>
        <test-run-list
          class="test-runs-list"
          :data="runs"
          :tab="tableFilter"
          :headers="filteredHeaders"
          :selected-runs="selectedRunsLocal"
          @selectTestRun="selectTestRun"
          @addConfiguration="handleAddConfiguration"
        />
        <div class="actions-container d-flex justify-end py-4 pr-6">
          <v-btn
            class="font-inter font-weight-bold text-none ml-3"
            dark
            depressed
            color="blue"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
            @click="handleCreateTestPlan"
          >
            {{ $t('plans.createTestPlan') }}
          </v-btn>
        </div>
      </div>
      <div
        v-if="runs.length <= 0"
        class="d-flex flex-row justify-end py-4 mt-9"
      >
        <v-btn
          class="primary text-capitalize"
          depressed
        >
          {{ $t('plans.createTestPlan') }}
        </v-btn>
      </div>
    </v-card>
    <add-configuration-dialog
      :open="isOpenAddConfiguration"
      @close="isOpenAddConfiguration = false"
    />
  </div>
</template>
<script>
import SearchField from '@/components/Form/SearchField';
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';
import AddConfigurationDialog from '@/views/Tests/Plans/Components/Configuration/Index';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import {
  runStateMap,
} from '@/constants/grid.js';
import { createNamespacedHelpers, mapGetters, mapState, mapActions } from 'vuex';
import makePlanService from '@/services/api/plan';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';

const { mapActions: mapRunActions } = createNamespacedHelpers('run');
const { mapMutations: mapPlanMutations } = createNamespacedHelpers('plan');

let planService;
export default {
  components: {
    SearchField,
    TestRunList,
    RunFilter,
    AddConfigurationDialog,
    SettingsMenu,
  },
  data() {
    return {
      imageSrc: '',
      actionMenu: false,
      filter: 'ongoing',
      // testruns: [],
      form: {
        projectName: '',
        description: '',
        runs: [],
      },
      isColumnFilter: false,
      showAddUsersView: true,
      toBeSelectedUser: [],
      selectedRunsLocal: [],
      selectedUser: [],
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      headers: [],
      isOpenAddConfiguration: false,
    };
  },
  computed: {
    ...mapState('plan', ['testPlan', 'selectedRuns']),
    ...mapState('run', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    runs() {
      let filtered = this.items.filter((item) => (item.customFields.archived === ( this.filter === 'ongoing' ? false : true )));
      const finalRuns = filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = `${createdFormat.getFullYear()}-${String(createdFormat.getMonth()+1).padStart(2,'0')}-${new String(createdFormat.getDate()).padStart(2,'0')}`

        return {
          ...item,
          processedStatus: this.staticValues(item, runStateMap, 'New'),
          createdAt
        }
      }); 
      return finalRuns
    },
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedTestRunLength() {
      return this.runs.filter((run) => run.toBeSelected).length;
    },
    selectedTestRunsLength() {
      return this.selectedRunsLocal.length
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMilestones() {
      if (this.searchFilter && this.mainFilter == false) {
        const filtered = this.milestones.filter((item) => this.matchesFilter(item));
        return filtered.length > 0 ? filtered : 'SEARCH_BACKEND';
      }
      return this.milestones;
    },
    milestones() {
      let filtered = this.items.filter((item) => item.customFields.runstatus === this.filter);

      return filtered.map((item) => ({
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
      }));
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredMilestones(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
  },
  created() {
    if(!this.dynamicHeaders.addTestRun) {
      this.initializeHeaders({ type: 'addTestRun' });
    }
    this.headers = this.dynamicHeaders.addTestRun;
    planService = makePlanService(this.$api);
    let handle = this.currentAccount.handle;
    let projectKey = this.$route.params.key;
    this.getProjectRun(handle, projectKey);
    this.selectedRunsLocal = [];
  },
  mounted() {
    this.runs.forEach((run) => {
      this.$set(run, 'toBeSelected', false);
    });
  },
  methods: {
    ...mapPlanMutations(['UPDATE_SELECTED_RUNS']),
    ...mapRunActions(['getAllTestRuns']),
    ...mapActions("headers", ['initializeHeaders']),
    getProjectRun(handle, projectKey) {
      this.getAllTestRuns({ handle: handle, projectKey: projectKey, perPage: 99999, currentPage: 0 });
    },
    handleAddConfiguration(value) {
      this.isOpenAddConfiguration = true;
    },
    async handleCreateTestPlan() {
      const runsList = this.selectedRunsLocal.map(
          ({
              uid,
              externalId,
              source,name,
              link,priority,
              status,
              testPlanUid,
              customFields: {tags}
          }) => ({
            uid,
            externalId,
            source,
            name,
            link,
            priority,
            status,
            testPlanUid,
            customFields: {tags}}
          ))
      const payload = {
        ...this.testPlan,
        runs: runsList || []
      }
      try {
          const response = await planService.createTestPlan(
            this.$route.params.handle,
            this.$route.params.key,
            payload
          );

          if (response.status === 200) {
            if(!this.$route.query.activeAddMilestone){
              this.$router.push({
                name: 'TestPlans',
                params: {
                  handle: this.$route.params.handle,
                  key: this.$route.params.key,
                }
              });
            } else {
              this.$router.push({
                name: 'MilestoneTestActivities',
                params: {
                  handle: this.$route.params.handle,
                  key: this.$route.params.key
                },
              });
            }

            showSuccessToast(this.$swal, this.$t('testruns.testplan_dialog.success'));
          }
        } catch (error) {
          showErrorToast(this.$swal, this.$t('testruns.testplan_dialog.failed'));
          console.error("Failed to create Test plan :", error);
        }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick(event) {
      event.preventDefault();

      this.$router.replace({ name: 'TestPlanCreate' });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
      this.$router.push({ name: 'MilestoneCreate' });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addTestRuns() {
      this.runs.forEach((run) => {
        if (run.toBeSelected) {
          this.$set(run, 'selected', true);
          this.$set(run, 'toBeSelected', false);
        }
      });
    },
    removeTestRuns() {
      this.runs.forEach((run) => {
        if (run.toBeSelected) {
          this.$set(run, 'selected', false);
          this.$set(run, 'toBeSelected', false);
        }
      });
    },
    selectTestRun(selectedRuns) {
      this.selectedRunsLocal = selectedRuns
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);
      const sourceMatch = item.source.toLowerCase().includes(lowerCaseFilter);

      return nameMatch || sourceMatch;
    },
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    openCreateTestRun() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
        query: {
          page: 'TestPlanAddRuns'
        }
      });
    }
  },
};
</script>
  
<style lang="scss" scoped>
.test-plans-add-runs {
  position: relative;
  padding-bottom: 68px;
  height: calc(100vh - 90px - 8px);

  display: flex;
  flex-direction: column;
}

.test-plans-add-runs-header {
  flex: 0 1 138px;
}

.test-plans-add-runs-wrapper {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
}

.search-container {
  flex: 0 1 40px;
}

.plans-list {
  flex: 1 1 auto;
  overflow: auto;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;

  display: flex;
  align-items: center;
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

p{
  font-size: 14px;
  font-family: Inter;
}
</style>
